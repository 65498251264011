/* Breite des Off-Canvas-Menüs  */
@off-canvas-menu-width: 250px;

/* Breakpoints  */
@only-mini-devices: ~"screen and (max-width: 320px)";

@only-small-devices: ~"screen and (max-width: 567px)";

@only-small-devices-for-grid: ~"screen and (max-width: 860px)";

@only-medium-devices : ~"screen and (min-width: 568px) and (max-width: 991px)";

@only-large-devices : ~"screen and (min-width: 992px) and (max-width: 1240px)";

@only-extra-large-devices : ~"screen and (min-width: 1241px)";

@medium-devices-and-grater: ~"screen and (min-width: 568px)";

@large-devices-and-grater: ~"screen and (min-width: 992px)";

@large-devices-and-smaller: ~"screen and (max-width: 1240px)";

@medium-devices-and-smaller: ~"screen and (max-width: 991px)";

@medium-and-large-devices: ~"screen and (min-width: 568px) and (max-width: 1240px)";

@screen-sm-min: ~"screen and (min-width: 480px)";
@screen-md-min: ~"screen and (min-width: 568px)";
@screen-lg-min: ~"screen and (min-width: 992px)";
@screen-xl-min: ~"screen and (min-width: 1241px)";

@screen-enormous-min: ~"screen and (min-width: 2100px)";

@screen-xs-max: ~"screen and (max-width: 479px)";
@screen-sm-max: ~"screen and (max-width: 567px)";
@screen-md-max: ~"screen and (max-width: 991px)";
@screen-lg-max: ~"screen and (max-width: 1240px)";

/* Farben  */
@dark-font: #4C4C4C;
@railjet-red: #7B0E07;
@karmin-red: #9d1023;
@gray-white: #e7e4d8;
@blue-549: #7db9cd;
@dark-red: #AB0020;
@oebb-red: #E2002A;
@content-link-red: #E2002A;
@oebb-grey: #878787;
@black: #000000;
@black-70: #4C4C4C;
@black-60: #666666;
@black-55: #737373;
@black-40: #999999;
@black-30: #B3B3B3;
@black-20: #CCCCCC;
@black-10: #E5E5E5;
@black-5: #F2F2F2;
@black-4: #F5F5F5;
@black-3: #F7F7F7;
@black-2: #FAFAFA;
@white: #FFFFFF;
@error: #BD352E;
@error-background: #F8EAEA;
@warning: #F89306;
@warning-background: #FEF4E6;
@success: #51A351;
@success-background: #EDF6ED;

@color-ms-word: #185abd;
@color-ms-excel: #107c41;
@color-ms-powerpoint: #c43e1c;
@color-ms-outlook: #1480d8;
@color-ms-teams: #464eb8;
@color-ms-yammer: #0078d4;
@color-ms-onenote: #7719aa;
@color-ms-visio: #185abd;
@color-ms-forms: #038387;
@color-ms-powerbi: #f2c811;
@color-ms-project: #107c41;
@color-ms-sharepoint: #038387;
@color-ms-stream: #bc1948;
@color-ms-viva: #5b5fc7;
@color-ms-powerautomate: #0f4ac7;
@color-ms-delve: #bc1948;
@color-ms-calendar: #0364b8;
@color-ms-lists: #87447d;
@color-ms-planner: #185c37;
@color-ms-todo: #2564cf;
@color-zip: #d59d0a;
@color-pdf: #ff1b0e;

@container-padding: 40px;
@container-padding-medium-devices: 20px;
@container-padding-small-devices: 10px;

@header-height: 180px;
@header-height-medium-devices: 125px;
@header-height-small-devices: 90px;

@font-default: "Frutiger Next Regular", "Source Sans Pro Regular", Arial, san-serif;
@font-bold: "Frutiger Next Bold", "Source Sans Pro Bold", Arial, san-serif;
@font-light: "Frutiger Next Light", "Source Sans Pro Light", Arial, san-serif;

@icon-name-bullet-point-primary: "../../images/point-red.svg";
@icon-name-bullet-point-secondary: "../../images/point-gray.svg";
